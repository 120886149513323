import { useEffect } from "react";
import "./App.css";

function App() {
  useEffect(() => {
    // Simulate an HTTP redirect:
    window.location.replace("https://omi1.com?oldweb=1");
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://omi1.com?oldweb=1"
          rel="noopener noreferrer"
        >
          New domain omi1.com
        </a>
      </header>
    </div>
  );
}

export default App;
